<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { faXmark } from '@fortawesome/pro-light-svg-icons'

import { pageTitle } from '@consumer/logic/v2/header'

import { trackEvent } from '@shared/tracking'

import type Theme from '@consumer/enums/Consumer/Theme'
import { isDesktop } from '@consumer/utils/breakpoints'
import GiftlyLogo from './Header/GiftlyLogo.vue'

withDefaults(defineProps<{
  currentTheme?: Theme
  hideCloseButton?: boolean
}>(), {  })

const emit = defineEmits(['close'])

const closeButtonEl = ref<HTMLButtonElement>()

provide('currentTheme', __props.currentTheme)

function onClickClose (event: Event) {
  trackEvent('click', closeButtonEl.value)
  emit('close')
}

defineExpose({
  closeButtonEl,
})
</script>

<template>
  <div
    class="w-full z-navbar bg-white flex items-center px-3
      lg:px-0 lg:max-w-screen-lg mx-auto"
    :style="{ height: isDesktop ? '92px' : '58px' }"
  >
    <div class="flex items-center gap-4">
      <GiftlyLogo/>

      <component
        :is="pageTitle?.href ? Link : 'div'"
        :href="pageTitle?.href"
        class="no-underline group"
      >
        <h1
          class="text-sm leading-none font-medium text-v2-tertiary"
          :class="pageTitle?.href && 'hover:text-navy'"
        >
          {{ pageTitle?.text }}
        </h1>
      </component>
    </div>

    <slot/>

    <div class="flex-grow"/>

    <button
      ref="closeButtonEl"
      autofocus
      type="button"
      class="
        outline-none ring-0 z-1
        bg-white border-1 border-solid rounded-v2md
        text-v2-tertiary border-grey-primary
        hover:text-navy hover:border-v2-tertiary
        flex items-center justify-center
      ]"
      style="height: 44px; width: 44px;"
      @click.prevent="onClickClose"
    >
      <span class="sr-only">Close search</span>
      <V2Icon :icon="faXmark" size="md"/>
    </button>
  </div>
</template>

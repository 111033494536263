import type { ShippingMethod } from '@consumer/types/GiftDeliveryData'

export interface ShippingOption {
  key: ShippingMethod
  name: string
  upgradeFee: number
  estimatedDeliveryDate: Date | null
}

export const shippingOptions = [
  {
    key: 'firstclass',
    name: 'First-Class Mail',
    upgradeFee: 0,
    estimatedDeliveryDate: null,
  },
  {
    key: 'firstclass_track',
    name: 'First-Class Mail with Tracking',
    upgradeFee: 3,
    estimatedDeliveryDate: null,
  },
  {
    key: 'priority',
    name: 'Priority Mail with Tracking',
    upgradeFee: 7,
    estimatedDeliveryDate: null,
  },
] satisfies ShippingOption[]

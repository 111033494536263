<script lang="ts">
import type GiftTemplate from '@consumer/types/GiftTemplate'
import type { GiftIdea } from '@consumer/types/serializers'
import type { SpecOptions } from '@consumer/composables/spec'
import type { MaybeRefOrGetter } from 'vue'
import { encodeGift } from '@consumer/composables/giftTemplate'
import { customizer } from '@consumer/api'
import { cloneDeep, isBlank } from '@shared/object'

// TODO: Unify links to new customizer with the approach in CustomizerModal.
export function customizerPathFromIdea (
  idea?: GiftIdea | GiftTemplate,
  specOptionsRef?: MaybeRefOrGetter<SpecOptions>,
) {
  const urlParams = new URLSearchParams('')

  const specOptions = toValue(specOptionsRef)
  if (specOptions?.purchaseMethod) urlParams.set('purchase_method', specOptions.purchaseMethod)
  if (specOptions?.spec) urlParams.set('spec', specOptions.spec)

  if (idea) urlParams.set('gift', encodeGift(idea))

  return customizer.show.path({ query: urlParams })
}
</script>

<script lang="ts" setup>import { toRef as _toRef, computed as _computed } from 'vue';

import { openCustomizerModal } from '@consumer/services/modals'
import { useSpec } from '@consumer/composables/spec'
import { useSharedData } from '@consumer/composables/sharedData'
import { isDesktop } from '@consumer/utils/breakpoints'

withDefaults(defineProps<{
  idea?: GiftIdea
  name?: string
}>(), {  })

const specOptions = useSpec()

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');
const gift = _computed<GiftTemplate>(() => {
  if (!__props.idea) return {}
  const gift = { ...cloneDeep(__props.idea), giftlyDesignName: __props.idea.giftlyMetadata?.designName }
  delete gift.giftlyMetadata?.designName
  return reactive(gift)
})

const customizeUrl = _computed(() => customizerPathFromIdea({ ...cloneDeep(__props.idea), giftTags: [] }, specOptions))

const alt = _computed(() => {
  if (isBlank(__props.idea)) return 'Create My Own'

  const tagline = __props.idea.giftlyMetadata?.tagline
  const places = __props.idea.places?.map(p => p?.name).join(', ')
  return `Send a gift $${__props.idea.amount} for ${tagline} at ${places}`
})

const canUseTools = isDesktop.value && currentUser.value?.canEditGiftIdeas

const GiftIdeaTools = !import.meta.env.SSR && canUseTools
  ? defineAsyncComponent(() => import('@consumer/admin/GiftIdeaTools.vue'))
  : 'template'
</script>

<template>
  <a
    :href="customizeUrl"
    :name="name"
    :alt="alt"
    class="relative clickable"
    rel="nofollow"
    @click.exact.prevent="openCustomizerModal({ gift, specOptions })"
  >
    <GiftlyV3Image
      :gift="gift"
      :createMyOwn="!idea"
    >
      <div class="absolute inset-0 bg-primary-base opacity-20 hidden group-hover:block"/>
    </GiftlyV3Image>

    <GiftIdeaTools
      v-if="idea?.id && canUseTools"
      :idea="idea"
      :gift="gift"
    />
  </a>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import { faXmark } from '@fortawesome/pro-light-svg-icons'

import {
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { whenever } from '@vueuse/core'

const open = defineModel<boolean>('open')

const onClose = () => open.value = false

const closeButtonEl = _ref<HTMLButtonElement>()
whenever(open, () => nextTick(() => closeButtonEl.value?.focus()))
</script>

<template>
  <TransitionRoot :show="open">

    <dialog
      id="user-menu"
      class="fixed inset-0 w-full h-full bg-navy bg-opacity-0 z-dialog fullscreen"
      open
    >
      <div class="relative w-full h-full overflow-y-auto">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div class="fixed inset-0 bg-navy bg-opacity-40" @click="onClose"/>
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full scale-x-0"
          enterTo="translate-x-0 scale-x-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="translate-x-0 scale-x-100"
          leaveTo="translate-x-full scale-x-0"
        >
          <div class="bg-white my-3 ml-3 rounded-l-v2lg shadow-v2lg relative">
            <button
              ref="closeButtonEl"
              autofocus
              type="button"
              class="absolute top-6 right-4 outline-none ring-0 z-1"
              @click="onClose"
            >
              <span class="sr-only">Close search</span>
              <V2Icon
                :icon="faXmark"
                size="md"
                class="text-v2-tertiary hover:text-navy"
              />
            </button>

            <slot/>
          </div>
        </TransitionChild>
      </div>
    </dialog>
  </TransitionRoot>
</template>

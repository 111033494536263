// JsFromRoutes CacheKey eda13cce7ad07b4444c8150b19bd9bd9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/gift/landing/:id/:token'),
  buyAgain: /* #__PURE__ */ definePathHelper('get', '/gift/buy_again/:id/:token'),
  downloadPdf: /* #__PURE__ */ definePathHelper('get', '/gift/downloadpdf/:id/:token'),
  cancelGift: /* #__PURE__ */ definePathHelper('post', '/consumer/buyer_landing/gift_cancel/:id/:token'),
  resendGift: /* #__PURE__ */ definePathHelper('post', '/consumer/buyer_landing/gift_resend/:id/:token'),
  editGifterEmail: /* #__PURE__ */ definePathHelper('post', '/consumer/buyer_landing/gift_edit_email/:id/:token'),
  fetchGift: /* #__PURE__ */ definePathHelper('get', '/consumer/buyer_landing/fetch_gift/:id'),
}

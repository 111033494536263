<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { isDesktop } from '@consumer/utils/breakpoints'

import { useWindowScroll } from '@vueuse/core'

import type { FeaturedCity } from '@consumer/types/serializers'
import CategoryBar from './Header/CategoryBar.vue'
import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'
import SearchBarDesktop from './Header/SearchBarDesktop.vue'
import SearchBarMobile from './Header/SearchBarMobile.vue'
import UserMenu from './Header/UserMenu.vue'
import Overlay from './Overlay.vue'

defineEmits(['close'])

withDefaults(defineProps<{ city?: FeaturedCity }>(), {  })

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 150)
</script>

<template>
  <div
    class="fixed top-0 w-full z-navbar bg-white"
    :class="scrolled && 'lg:shadow-v2md'"
  >
    <V2Container class="!p-0 w-full">
      <Transition name="slide-down">
        <MainContainer v-show="isDesktop || (!isDesktop && !scrolled)" withSearchBar>
          <div class="w-full flex items-center justify-between h-15">
            <GiftlyLogo/>

            <div class="hidden lg:block">
              <SearchBarDesktop :city="city"/>
            </div>

            <UserMenu/>
          </div>
        </MainContainer>
      </Transition>
      <div class="px-3 py-2 lg:hidden">
        <SearchBarMobile/>
      </div>
    </V2Container>
  </div>

  <div class="h-[127.5px] lg:h-[84px]"/>

  <CategoryBar/>

  <Overlay/>
</template>

import { injectionKey } from '@shared/injection'
import { usePage } from '@consumer/composables/sharedData'

export interface SpecOptions {
  purchaseMethod: string
  spec?: string
}

const specInjectionKey = injectionKey<Ref<SpecOptions>>('spec')

export function useSpec (spec?: string): Ref<SpecOptions> {
  const page = usePage()

  const parentOptions = inject(specInjectionKey, undefined)
    || toRef(() => ({ purchaseMethod: page.component?.toLowerCase() }))

  if (!spec) return parentOptions

  const specOptions = toRef(() => {
    const options: SpecOptions = { ...parentOptions.value }

    if (spec) options.spec = spec

    return options
  })

  provide(specInjectionKey, specOptions)

  return specOptions
}

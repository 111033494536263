<script setup lang="ts">
import { faBars, faUser } from '@fortawesome/pro-light-svg-icons'

import type { CurrentUser } from '@consumer/types/serializers'

const open = defineModel<{ open: boolean }>()

const currentUser = inject<Ref<CurrentUser | null>>('currentUser')

const hasProfilePicture = computed(() => currentUser?.value?.profilePictureUrl
  && !currentUser?.value?.profilePictureUrl.includes('gravatar'))
</script>

<template>
  <button
    class="
      flex items-center justify-center gap-3
      rounded-v2md border-1 border-solid border-grey-primary
      active:text-navy lg:hover:text-navy"
    :class="open ? 'text-navy' : 'text-v2-tertiary'"
    style="height: 44px; width: 76px;"
    data-track-ga="nav-menu-open"
  >
    <div
      v-if="hasProfilePicture"
    >
      <img
        class="rounded-v2sm"
        :src="currentUser?.profilePictureUrl"
        height="26"
        width="26"
      >
    </div>
    <div
      v-else-if="currentUser?.initials"
      class="text-xs text-v2-blue-royal font-semibold
        bg-v2-blue-cool rounded-v2sm flex items-center justify-center"
      style="height: 26px; width: 26px;"
    >
      {{ currentUser.initials }}
    </div>
    <V2Icon v-else :icon="faUser" size="md"/>
    <V2Icon :icon="faBars" size="md"/>
  </button>
</template>

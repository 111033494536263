<script setup lang="ts">
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { Link } from '@inertiajs/vue3'

import { pageTitle } from '@consumer/logic/v2/header'

import {
  mobileSummaryOpen,
  shouldScrollToMobileSummary,
  useCheckoutStore,
} from '@consumer/stores/checkout'
import { formatCurrency } from '@consumer/utils/currency'
import checkoutConfig from '@consumer/services/checkoutConfig'

import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'
import UserMenu from './Header/UserMenu.vue'

defineEmits(['close'])

const checkoutData = useCheckoutStore()

const onClickMobileSummaryCarousel = () => {
  if (!checkoutData.readyToCheckout) {
    mobileSummaryOpen.value = !mobileSummaryOpen.value
  }
  else {
    shouldScrollToMobileSummary.value = true
  }
}
</script>

<template>
  <div class="w-full z-navbar bg-white">
    <V2Container class="!p-0 w-full">
      <MainContainer>
        <div class="w-full flex items-center justify-between">
          <div class="flex items-center gap-4">
            <GiftlyLogo/>
            <Link :href="pageTitle.href ?? '/'" class="no-underline group">
              <h1 class="text-sm font-medium text-v2-tertiary hover:text-navy">
                {{ pageTitle.text }}
              </h1>
            </Link>
          </div>

          <div class="flex items-center gap-2 lg:gap-4">
            <div
              class="lg:hidden px-4 py-2.5 flex items-center
                border-1 border-solid border-grey-primary rounded-v2md
                text-navy font-semibold
              "
              style="height: 44px;"
            >
              <div class="flex items-center clickable gap-2" @click="onClickMobileSummaryCarousel">
                <span class="flex-1 text-right">Total:</span>
                <span v-if="checkoutConfig.loaded">{{ formatCurrency(checkoutData.total) }}</span>
                <LoadingIndicator v-else/>
                <V2Icon
                  :icon="!mobileSummaryOpen || checkoutData.readyToCheckout
                    ? faChevronDown
                    : faChevronUp"
                />
              </div>
            </div>
            <UserMenu/>
          </div>
        </div>
      </MainContainer>
    </V2Container>
  </div>
</template>

<script setup lang="ts">import { ref as _ref, toRef as _toRef } from 'vue';
const initialCity = __MACROS_toRef(__props, "city");

import { toRef as __MACROS_toRef } from "vue";
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { useWindowScroll, useUrlSearchParams } from '@vueuse/core'

import { type Suggestion } from '@consumer/logic/v2/header'
import { searchBarFocused } from '@consumer/modules/v2/layout/Overlay.vue'

import { trackEvent } from '@shared/tracking'
import type { FeaturedCity } from '@consumer/types/serializers'
import DesktopInputField from './SearchBar/DesktopInputField.vue'

const compact = defineModel<boolean>('compact')

withDefaults(defineProps<{ city?: FeaturedCity }>(), {  })

const searchParams = useUrlSearchParams()

let category = _ref<string>(searchParams.category?.toString())
let focusedCategory = _ref(false)
const categoryFieldRef = ref<InstanceType<typeof DesktopInputField>>()

const onFocusCategory = () => {
  focusedCategory.value = true
  trackEvent('click', 'gc-index-category-field')
}

const selectSuggestedCategory = (suggestion: Suggestion) => {
  category.value = suggestion.label
  if (suggestion.city) {
    city.value = suggestion.city
  }
  cityFieldRef.value?.focus()
}

function getInitialCityName () {
  if (__props.city) {
    if (__props.city.stateCode) {
      return `${__props.city.name}, ${__props.city.stateCode}`
    } return __props.city.name
  }
  return ''
}

let city = _ref<string>(__props.city ? getInitialCityName() : searchParams.city?.toString())
let focusedCity = _ref(false)
const cityFieldRef = ref<InstanceType<typeof DesktopInputField>>()

const onFocusCity = () => {
  focusedCity.value = true
  trackEvent('click', 'gc-index-city-field')
}

const selectSuggestedCity = (suggestion: Suggestion) => {
  city.value = suggestion.label
  onSubmit()
}

const onSubmit = () => {
  const searchParams = new URLSearchParams()
  if (category.value) {
    searchParams.append('category', category.value)
  }
  if (city.value) {
    searchParams.append('city', city.value)
  }

  trackEvent('click', 'gc-index-search-button', 'Search')

  window.location.href = `/search?${searchParams.toString()}`
}

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 104)
watch(
  () => [scrolled?.value, focusedCategory.value, focusedCity.value],
  () => {
    if (!scrolled?.value) {
      compact.value = false
    }
    else {
      compact.value = !focusedCategory.value && !focusedCity.value
    }

    searchBarFocused.value = focusedCategory.value || focusedCity.value
  },
)
</script>

<template>
  <div
    class="flex justify-center items-center transition transition-all duration-300"
  >
    <div
      class="search-form bg-white p-1
        border-1 border-solid border-grey-primary rounded-v2md shadow-v2sm
        flex gap-1 items-center justify-center
      "
      :style="{ height: compact ? '44px' : '60px' }"
    >
      <DesktopInputField
        ref="categoryFieldRef"
        v-model="category"
        name="category"
        :compact="compact"
        :placeholder="compact ? `What they like` : `Business, category or interest`"
        @focus="onFocusCategory"
        @blur="focusedCategory = false"
        @keyup.enter="cityFieldRef?.focus()"
        @selectSuggestion="selectSuggestedCategory"
      />

      <div
        class="flex bg-grey-primary"
        :class="focusedCategory || focusedCity ? 'opacity-0' : 'opacity-100'"
        style=" height: 24px;width: 1px;"
      />

      <DesktopInputField
        ref="cityFieldRef"
        v-model="city"
        name="city"
        :compact="compact"
        :placeholder="compact ? `Where they live` : `Neighborhood, city, state or zip`"
        @focus="onFocusCity"
        @blur="focusedCity = false"
        @keyup.enter="onSubmit"
        @selectSuggestion="selectSuggestedCity"
      />

      <V2Button
        variant="primary"
        type="submit"
        :icon="faMagnifyingGlass"
        size="small"
        class="h-full !p-0 hidden lg:flex aspect-square"
        @click="onSubmit"
      />
    </div>
  </div>
</template>

import type {
  CustomizerOptions,
  GiftlyMetadata,
  PlaceForCheckout,
  CurrentUser,
  GiftGreeting,
  GreetingCard,
} from '@consumer/types/serializers'

import { defineStore } from 'pinia'

import { decodeGift } from '@consumer/composables/giftTemplate'
import { mergeObjects } from '@consumer/helpers/object'
import checkoutConfig from '@consumer/services/checkoutConfig'
import { parseDate } from '@consumer/utils/date'
import { notifyError } from '@shared/errors'
import { deepClean, clean } from '@shared/object'
import { useUrlSearchParams } from '@vueuse/core'
import { checkout as checkoutApi } from '@consumer/api'

import type DeliveryData from '@consumer/types/GiftDeliveryData'
import type GiftFees from '@consumer/types/GiftFees'
import type GiftTemplate from '@consumer/types/GiftTemplate'
import type PaymentData from '@consumer/types/GiftPaymentData'
import { defaultV3, v3WithImageBg } from '@consumer/logic/v3'

const CHECKOUT_DATA_KEY = 'giftly.checkoutData'

export interface CheckoutProps {
  customizerOptions?: CustomizerOptions

  amount?: number
  giftlyDesignName?: string

  purchaseMethod?: string
  gclid?: string

  gifterEmail?: string

  deliveryMethod?: string
  gifterName?: string

  billingName?: string
  billingZip?: string

  backgroundImageUrl?: string
  giftlyVersion?: number

  agreeToESignDisclosure?: boolean
  agreeToTermsAndConditions?: boolean
  optInMailer?: boolean

  giftlyMetadata?: GiftlyMetadata
  referralSpec?: string
}

export interface CheckoutStepData {
  completed?: boolean
}

export interface CheckoutGiftData extends CheckoutStepData {
  amount: number
  places?: PlaceForCheckout[]
  gclid?: string
  purchaseMethod: string
  backgroundImageUrl?: string
  giftlyDesignName: string
  giftlyVersion?: number
  giftlyMetadata: GiftlyMetadata
}

export interface CheckoutContactData extends CheckoutStepData {
  senderEmail?: string
  optInMailer?: boolean
}

export interface CheckoutGreetingCard extends GreetingCard {
  category?: string
  categoryId?: number
}

export type CheckoutDeliveryData = DeliveryData & CheckoutStepData
export interface CheckoutGreetingData extends Partial<Pick<GiftGreeting, 'from' | 'to' | 'message'>>, CheckoutStepData {
  // The selected greeting card, if any.
  card?: CheckoutGreetingCard
  // Not the selected card, but this can be used to set the selected card back
  // to the last uploaded photo.
  customUpload?: CheckoutGreetingCard
}

export type PaymentMethod = 'card' | 'card_existing' | 'apple_pay' | 'google_pay'

export interface CheckoutPaymentData extends PaymentData, CheckoutStepData {
  paymentMethod?: PaymentMethod
  stripeToken?: string | null
  savePaymentInformation?: boolean
  gifterPassword?: string
  discountAmount?: number
  goldDiscountAmount?: number
  agreeToESignDisclosure?: boolean
  agreeToTermsAndConditions?: boolean
}

export type CheckoutPurchaseData = CheckoutStepData

export interface CheckoutData {
  gift: CheckoutGiftData
  contact: CheckoutContactData
  delivery: CheckoutDeliveryData
  greeting: CheckoutGreetingData
  payment: CheckoutPaymentData
  purchase: CheckoutPurchaseData
}

export type CheckoutStepType = Exclude<keyof CheckoutData, 'purchase'>

function missingFee (amount: number, giftFeeSchedule: any) {
  if (giftFeeSchedule.length) {
    notifyError(new Error(`Missing fee for gift amount ${amount}`), { extra: { amount, giftFeeSchedule } })
  }
  return 0
}

export const useCheckoutStore = defineStore('checkout', {
  state: (): CheckoutData => ({
    gift: {
      amount: 100,
      giftlyDesignName: defaultV3,
      completed: true, // Gift step is always going to be completed at start of checkout
      giftlyVersion: 3,
      purchaseMethod: 'unknown',
      giftlyMetadata: {},
    },
    contact: {},
    delivery: {
      deliveryMethod: 'email',
      deliveryDate: new Date(),
      recipientAddress: {},
      shippingMethod: 'firstclass',
    },
    greeting: {},
    payment: {},
    purchase: {},
  }),

  getters: {
    fees ({ gift, delivery }): GiftFees {
      // Calculate processing fee based on gift amount
      const giftFeeSchedule = checkoutConfig.giftFeeSchedule
      const processingFee = giftFeeSchedule.find(({ min, max }) => {
        return gift.amount >= min && (!max || gift.amount <= max)
      })?.fee || missingFee(gift.amount, giftFeeSchedule)

      // Calculate shipping fee if delivery by mail
      let baseShippingFee = 0
      let upgradedShippingFee = 0
      if (delivery.deliveryMethod === 'mail') {
        baseShippingFee = checkoutConfig.shipping.baseFee
        upgradedShippingFee = checkoutConfig.shipping.options.find(option => (
          option.key === delivery.shippingMethod
        ))?.upgradeFee || 0
      }

      const greetingCardFee = this.greetingCardFee

      const totalShippingFee = baseShippingFee + upgradedShippingFee

      return {
        processingFee,
        baseShippingFee,
        upgradedShippingFee,
        totalShippingFee,
        greetingCardFee,
        loading: !checkoutConfig.loaded,
      }
    },

    greetingCardFee ({ greeting }): number {
      const { card } = greeting
      return Number.parseFloat((card?.custom ? 1 : card?.cost ?? 0).toString())
    },

    totalFees ({ payment }): number {
      const discount = payment.goldDiscountAmount ?? payment.discountAmount ?? 0
      const feesSum = this.fees.processingFee + this.fees.greetingCardFee + this.fees.totalShippingFee
      return feesSum - discount
    },

    total ({ gift }): number {
      return parseFloat(this.totalFees.toString()) + parseFloat(gift.amount.toString())
    },

    readyToCheckout ({ contact, delivery, greeting, payment }) {
      return contact.completed && delivery.completed && greeting.completed && payment.completed
    },

    checkoutProgress ({ contact, gift }) {
      if (!contact.completed) return // We need the user's email to notify them.

      const {
        amount,
        backgroundImageUrl,
        gclid,
        giftlyVersion,
        purchaseMethod,
        giftlyMetadata,
        giftlyDesignName,
      } = gift

      return {
        email: contact.senderEmail,
        optInMailer: contact.optInMailer,
        checkoutOptions: clean({
          amount,
          backgroundImageUrl,
          gclid,
          gifterEmail: contact.senderEmail,
          giftlyVersion,
          placeId: gift.places?.map(place => place.giftlyId)?.[0],
          purchaseMethod,
          giftlyDesignName,
          giftlyMetadata,
        }),
      }
    },
  },

  actions: {
    initialize (props: CheckoutProps, currentUser?: CurrentUser | null) {
      const urlParams = useUrlSearchParams('history')

      this.readFromLocalStorage({ skipGift: Boolean(urlParams.gift || props.amount) })

      // Hydrate from props
      mergeObjects(this.$state, deepClean({
        gift: {
          gclid: props.gclid,
          purchaseMethod: props.purchaseMethod,
        },
        contact: {
          senderEmail: props.gifterEmail,
          completed: this.contact.completed || Boolean(currentUser) || props.gifterEmail,
        },
        delivery: {
          method: this.delivery.completed
            ? this.delivery.deliveryMethod
            : (props.deliveryMethod ?? this.delivery.deliveryMethod ?? 'email'),
        },
        greeting: {
          from: props.gifterName,
        },
        payment: {
          paymentMethod: this.payment.paymentMethod,
          billingName: props.billingName,
          billingZip: props.billingZip,
          agreeToESignDisclosure: props.agreeToESignDisclosure,
          agreeToTermsAndConditions: props.agreeToTermsAndConditions,
        },
      }))

      if (urlParams.gift) {
        const gift = decodeGift(urlParams.gift.toString())
        this.updateGift(gift)
      }
      else if (props.amount) {
        const giftlyDesignName = props.giftlyDesignName

        this.updateGift(deepClean({
          amount: props.amount,
          giftlyDesignName,
          backgroundImageUrl: props.backgroundImageUrl,
          giftlyVersion: props.giftlyVersion,
          ...props.customizerOptions,
        }))
      }

      // Save progress once we have the user's email.
      watch(() => this.checkoutProgress, this.saveProgress, { immediate: true, deep: true })
    },

    saveProgress () {
      const data = this.checkoutProgress
      if (data) {
        checkoutApi.saveProgress({ data })
      }
    },

    updateGift (gift: GiftTemplate) {
      gift.giftlyMetadata ||= {}

      if (gift.giftlyMetadata?.maskColor) {
        gift.giftlyDesignName ||= v3WithImageBg
      }
      else {
        // NOTE: Auto-correct past gifts before composite.
        gift.giftlyDesignName = defaultV3
      }

      if (!gift.giftlyDesignName) {
        gift.giftlyMetadata.unsplashImageId = null as any
      }

      mergeObjects(this.$state.gift, gift)
    },

    markAsIncompleteStep (stepName: CheckoutStepType) {
      if (stepName && typeof this.$state[stepName] === 'object') {
        this.$state[stepName].completed = false
      }

      this.persistToLocalStorage()
    },

    saveCompletedStep (changes: Partial<CheckoutData>) {
      Object.entries(changes).forEach(([stepName, step]) => { step.completed = true })
      this.$patch(changes)
      this.persistToLocalStorage()
    },

    persistToLocalStorage () {
      try {
        localStorage.setItem(CHECKOUT_DATA_KEY, JSON.stringify(toRaw(this.$state)))
      }
      catch (error) {
        notifyError(error)
      }
    },

    readFromLocalStorage ({ skipGift }: { skipGift: boolean }) {
      try {
        const checkoutDataJSON = localStorage.getItem(CHECKOUT_DATA_KEY)

        if (checkoutDataJSON) {
          const checkoutData = deepClean(JSON.parse(checkoutDataJSON) as CheckoutData)

          if (skipGift) {
            delete checkoutData.gift // We don't want to override the gift info if it was provided.
          }

          // If data was loaded from JSON, we'll need to transform date strings into Date objects
          if (typeof checkoutData?.delivery?.deliveryDate === 'string') {
            checkoutData.delivery.deliveryDate = parseDate(checkoutData.delivery.deliveryDate)
          }

          mergeObjects(this.$state, checkoutData)
        }
      }
      catch (error) {
        this.clearLocalStorage() // Avoid errors in subsequent page loads.
        notifyError(error)
      }
    },

    clearLocalStorage () {
      try {
        localStorage.removeItem(CHECKOUT_DATA_KEY)
      }
      catch (error) {
        notifyError(error)
      }
    },

    markAsPurchased () {
      this.$state.purchase.completed = true
      this.clearLocalStorage()
    },
  },
})

export const mobileSummaryOpen = ref(false)
export const shouldScrollToMobileSummary = ref(false)

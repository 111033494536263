<script lang="ts" setup>
import {
  faUser,
  faArrowRightFromBracket,
  faGift,
  faCircleInfo,
  faHeadset,
  faGiftCard,
  faBriefcase,
} from '@fortawesome/pro-light-svg-icons'
import type { CurrentUser } from '@consumer/types/serializers'
import { myAccount } from '@consumer/api'
import MenuItem from './MenuItem.vue'

const currentUser = inject<Ref<CurrentUser | null>>('currentUser')

defineModel<boolean>('open')
</script>

<template>
  <div
    class="py-4 lg:p-3 flex flex-col lg:gap-3 border-b-1 border-grey-primary"
  >
    <MenuItem
      :label="currentUser ? `My Account` : `Sign Up`"
      :icon="faUser"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      :url="currentUser ? myAccount.purchases.path() : `/signup`"
    />
    <MenuItem
      :label="currentUser ? `Logout` : `Login`"
      :icon="faArrowRightFromBracket"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      :url="currentUser ? `/signout` : `/login`"
    />
  </div>
  <div class="py-4 lg:p-3 flex flex-col lg:gap-3">
    <MenuItem
      label="Browse"
      :icon="faGift"
      iconColor="#61BF49"
      iconBgColor="#E4FEDD"
      url="/browse"
    />
    <MenuItem
      class="how-it-works-modal-link"
      label="How it works"
      :icon="faCircleInfo"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      url="/how-it-works"
    />
    <MenuItem
      label="Help"
      :icon="faHeadset"
      iconColor="#A675C3"
      iconBgColor="#F7F0FA"
      url="/support"
    />
    <MenuItem
      label="Redeem"
      :icon="faGiftCard"
      iconColor="#FFBC10"
      iconBgColor="#FFF6DE"
      url="/entercode"
    />
    <MenuItem
      label="For Business"
      :icon="faBriefcase"
      iconColor="#AE3B00"
      iconBgColor="#FFDFCE"
      url="/corp"
    />
  </div>
</template>


<script setup lang="ts">import { ref as _ref } from 'vue';

import type { Component } from 'vue'
import { Popover, PopoverPanel } from '@headlessui/vue'
import { onClickOutside } from '@vueuse/core'
import type { Suggestion } from '@consumer/logic/v2/header'
import SuggestionCategory from './SuggestionCategory.vue'
import SuggestionCity from './SuggestionCity.vue'
import InputField from './InputField.vue'

withDefaults(defineProps<{
  name: string
  placeholder?: string
  compact?: boolean
}>(), {  })

const model = defineModel<string>()

interface Emit {
  (event: 'focus'): void
  (event: 'blur'): void
  (event: 'selectSuggestion', suggestion: Suggestion): void
}
const emit = defineEmits<Emit>()

let openSuggestions = _ref(false)

const onFocus = () => {
  emit('focus')
  openSuggestions.value = true
}

const onBlur = () => {
  openSuggestions.value = false
  emit('blur')
}

const onInput = () => {
  if (__props.name === 'category') {
    if (model.value) { openSuggestions.value = false }
    else { openSuggestions.value = true }
  }
  else {
    (suggestionComponentRef.value as typeof SuggestionCity).highlightFirstSuggestion()
  }
}

const selectSuggestion = (suggestion: Suggestion) => {
  emit('selectSuggestion', suggestion)
  onBlur()
}

const wrapperRef = ref<HTMLElement | null>(null)
onClickOutside(wrapperRef, () => {
  onBlur()
})

const SUGGESTION_COMPONENT: { [key: string]: Component } = {
  category: SuggestionCategory,
  city: SuggestionCity,
}

const suggestionComponent = computed(() => SUGGESTION_COMPONENT[__props.name])

const suggestionComponentRef = ref()
const inputFieldRef = ref<InstanceType<typeof InputField>>()

const hasResults = ref<boolean>(!model.value?.length)

const showPopoverPanel = computed(() => {
  if (__props.name === 'category') {
    return openSuggestions.value
  }
  else if (__props.name === 'city') {
    if (openSuggestions.value) {
      if ((model.value?.length ?? 0) > 0) {
        return hasResults.value
      }
      else {
        return true
      }
    }
    return false
  }
})

function highlightNextSuggestion (evt: KeyboardEvent) {
  evt.stopImmediatePropagation()
  if (__props.name === 'city') {
    (suggestionComponentRef.value as typeof SuggestionCity).highlightNextSuggestion()
  }
}

function highlightPreviousSuggestion (evt: KeyboardEvent) {
  evt.stopImmediatePropagation()
  if (__props.name === 'city') {
    (suggestionComponentRef.value as typeof SuggestionCity).highlightPreviousSuggestion()
  }
}

function selectHighlightedSuggestion (evt: KeyboardEvent) {
  evt.stopImmediatePropagation()
  if (__props.name === 'city') {
    (suggestionComponentRef.value as typeof SuggestionCity).selectHighlightedSuggestion()
    openSuggestions.value = false
  }
}

defineExpose({
  focus: () => inputFieldRef.value?.focus(),
  blur: () => inputFieldRef.value?.blur(),
})
</script>

<template>
  <Popover
    ref="wrapperRef"
    class="w-full h-full flex relative transition-all duration-300 ease-in-out"
    :style="{ width: compact ? '139px' : '260px' }"
  >
    <div class="w-full h-full relative">
      <div class="w-full h-full relative">
        <InputField
          ref="inputFieldRef"
          v-model="model"
          :name="name"
          :placeholder="placeholder"
          :compact="compact"
          :class="[
            'header-v2-search-input-field-desktop',
            compact ? 'header-v2-search-input-field-desktop-compact' : '',
          ]"
          :forceBold="true"
          @focus="onFocus"
          @input="onInput"
          @keydown.down="highlightNextSuggestion"
          @keydown.up="highlightPreviousSuggestion"
          @keydown.enter="selectHighlightedSuggestion"
        />
      </div>
      <Transition name="slide-down">
        <div v-show="showPopoverPanel" class="w-full">
          <PopoverPanel static class="w-full absolute mt-1.5 -mx-1 z-navbar">
            <component
              :is="suggestionComponent"
              ref="suggestionComponentRef"
              :input="model"
              @select="selectSuggestion"
              @hasResults="(val:boolean) => hasResults=val"
            />
          </PopoverPanel>
        </div>
      </Transition>
    </div>
  </Popover>
</template>

<style lang="scss">
.header-v2-search-input-field-desktop {
  &.header-v2-search-input-field-desktop-compact {
    input {
      padding: 8px 12px !important;
    }
  }
}
</style>

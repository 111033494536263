<script setup lang="ts">
import { faLocationDot } from '@fortawesome/pro-light-svg-icons'

import imgCitiesNewYork from '@consumer/assets/images/header-v2/cities/new-york.png'
import imgCitiesSanFrancisco from '@consumer/assets/images/header-v2/cities/san-francisco.png'
import imgCitiesChicago from '@consumer/assets/images/header-v2/cities/chicago.png'
import imgCitiesLosAngeles from '@consumer/assets/images/header-v2/cities/los-angeles.png'
import imgCitiesWashington from '@consumer/assets/images/header-v2/cities/washington.png'
import imgCitiesDenver from '@consumer/assets/images/header-v2/cities/denver.png'
import imgCitiesHouston from '@consumer/assets/images/header-v2/cities/houston.png'
import imgCitiesBoston from '@consumer/assets/images/header-v2/cities/boston.png'
import imgCitiesSanDiego from '@consumer/assets/images/header-v2/cities/san-diego.png'
import imgCitiesPortland from '@consumer/assets/images/header-v2/cities/portland.png'
import type { Suggestion } from '@consumer/logic/v2/header'
import AutocompleteApi from '@consumer/api/AutocompleteApi'

const props = defineProps<{ input?: string }>()

const apiSuggestions = ref<{
  label: string
  icon?: string
  image?: string
}[]>([])

watch(props, async () => {
  if (props.input) {
    const response = await AutocompleteApi.featuredCities({ query: { q: props.input } })
    apiSuggestions.value = response.map((suggestion: {name: string; stateCode: string}) => ({
      label: `${suggestion.name}, ${suggestion.stateCode}`,
      icon: faLocationDot,
    }))

    emit('hasResults', response.length > 0)
  }
})

const defaultSuggestions = [
  {
    label: 'Nearby',
    icon: faLocationDot,
  },
  {
    label: 'New York, NY',
    image: imgCitiesNewYork,
  },
  {
    label: 'San Francisco, CA',
    image: imgCitiesSanFrancisco,
  },
  {
    label: 'Chicago, IL',
    image: imgCitiesChicago,
  },
  {
    label: 'Los Angeles, CA',
    image: imgCitiesLosAngeles,
  },
  {
    label: 'Washington, DC',
    image: imgCitiesWashington,
  },
  {
    label: 'Denver, CO',
    image: imgCitiesDenver,
  },
  {
    label: 'Houston, TX',
    image: imgCitiesHouston,
  },
  {
    label: 'Boston, MA',
    image: imgCitiesBoston,
  },
  {
    label: 'San Diego, CA',
    image: imgCitiesSanDiego,
  },
  {
    label: 'Portland, OR',
    image: imgCitiesPortland,
  },
]

const suggestions = computed(() => {
  if (props.input) {
    return apiSuggestions.value
  }
  else {
    return defaultSuggestions
  }
})

const emit = defineEmits<{(e: 'select', value: Suggestion): void
  (e: 'hasResults', value: boolean): void
}>()

const selectSuggestion = (suggestion: Suggestion) => {
  emit('select', suggestion)
}

const highlightedIdx = ref(0)
function highlightNextSuggestion () {
  if (highlightedIdx.value < suggestions.value.length - 1) {
    highlightedIdx.value++
  }
}

function highlightPreviousSuggestion () {
  if (highlightedIdx.value > 0) {
    highlightedIdx.value--
  }
}

function highlightFirstSuggestion () {
  highlightedIdx.value = 0
}

function selectHighlightedSuggestion () {
  selectSuggestion(suggestions.value[highlightedIdx.value])
}

defineExpose({
  highlightNextSuggestion,
  highlightPreviousSuggestion,
  highlightFirstSuggestion,
  selectHighlightedSuggestion,
})
</script>

<template>
  <V2List class="w-full" data-testid="suggestion-city-list">
    <V2ListItem
      v-for="(suggestion, index) in suggestions"
      :key="suggestion.label"
      class="w-full cursor-pointer"
      :highlighted="index === highlightedIdx"
      :label="suggestion.label"
      :icon="suggestion.icon"
      :image="suggestion.image"
      data-testid="suggestion-city-item"
      @click="selectSuggestion(suggestion)"
    />
  </V2List>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})
</script>

<script lang="ts">
export const searchBarFocused = ref(false)
export const userMenuFocused = ref(false)
export const categoryBarFocused = ref(false)
</script>

<template>
  <Transition name="fade-in-out">
    <div
      v-show="(searchBarFocused || userMenuFocused)"
      v-bind="$attrs"
      class="
        hidden lg:block
        bg-navy bg-opacity-40 fixed inset-0 z-overlay
        transition-opacity duration-300 ease-in-out
      "
      style="margin-top: 84px;"
    />
  </Transition>
  <Transition name="fade-in-out">
    <div
      v-show="categoryBarFocused"
      v-bind="$attrs"
      class="
        hidden lg:block
        bg-navy bg-opacity-40 fixed inset-0 z-2
        transition-opacity duration-300 ease-in-out
      "
      style="margin-top: 159px;"
    />
  </Transition>
</template>

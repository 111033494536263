<script setup lang="ts">import { ref as _ref } from 'vue';

import { type Category } from '@consumer/logic/v2/header'
import { useWindowScroll } from '@vueuse/core'
import { categoryBarFocused } from '@consumer/modules/v2/layout/Overlay.vue'
import CategoryButton from './CategoryBar/Button.vue'
import CategoryDesktopDropdown from './CategoryBar/DesktopDropdown.vue'

withDefaults(defineProps<{
  categories: Category[]
}>(), {  })

let currentCategory = _ref<Category>()
let isFocused = _ref(false)
let isDropdownOpen = (categoryBarFocused)

const { y: windowY } = useWindowScroll()

watch(windowY, closeCategoryDropdown)

function openCategoryDropdown () {
  isDropdownOpen.value = Boolean(currentCategory.value?.links)
}

function closeCategoryDropdown () {
  isFocused.value = false
  isDropdownOpen.value = false
  currentCategory.value = undefined
}

function isCurrentCategory (category: Category) {
  return currentCategory.value?.title === category.title
}

async function showCategoryDropdown (category: Category) {
  setTimeout(() => {
    if (isCurrentCategory(category)) openCategoryDropdown()
  }, currentCategory.value ? 0 : 200)
  currentCategory.value = category
}

async function hideCategoryDropdown (category: Category) {
  setTimeout(() => {
    if (isFocused.value) return
    if (!currentCategory.value || isCurrentCategory(category)) {
      closeCategoryDropdown()
    }
  }, 200)
}
</script>

<template>
  <div class="w-full flex items-center justify-center">
    <div
      class="gap-0 flex items-center justify-center relative"
      @mouseenter="isFocused = true"
      @mouseleave="closeCategoryDropdown"
    >
      <template v-for="category, key in categories" :key="key">
        <CategoryButton
          class="pt-3"
          :category="category"
          :isActive="isCurrentCategory(category)"
          @mouseenter="showCategoryDropdown(category)"
          @mouseleave="hideCategoryDropdown(category)"
          v-on="category.path ? {} : { click: openCategoryDropdown }"
        />
      </template>

      <CategoryDesktopDropdown v-if="isDropdownOpen && currentCategory" :category="currentCategory"/>
    </div>
  </div>
</template>

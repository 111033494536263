<script lang="ts" setup>
import { categories as defaultCategories } from '@consumer/logic/v2/header'
import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons'
import CategoryBarDesktop from './CategoryBarDesktop.vue'
import CategoryBarMobile from './CategoryBarMobile.vue'

const currentTheme = inject('currentTheme')

const categories = computed(() => {
  let allCategories = defaultCategories

  if (currentTheme === 'holidays') {
    allCategories = [
      {
        title: 'Christmas',
        icon: faTreeChristmas,
        path: '/gift-ideas/occasions/christmas',
      },
      ...allCategories,
    ]
  }

  return allCategories
})
</script>

<template>
  <div
    class="relative bg-white transition-all duration-250 v2-header-category-bar"
  >
    <V2Container class="flex items-center justify-between z-navbar !max-w-full pt-2 !px-0">
      <CategoryBarMobile class="lg:hidden" :categories="categories"/>
      <CategoryBarDesktop class="hidden lg:flex" :categories="categories"/>
    </V2Container>
  </div>
</template>

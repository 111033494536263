import type CreditCard from '@consumer/types/CreditCard'
import type { GreetingCardCategory } from '@consumer/types/serializers'
import { parseDate } from '@consumer/utils/date'
import type DateStr from '@consumer/types/DateStr'
import { checkout as checkoutApi, greetingCards } from '@consumer/api'
import { shippingOptions, type ShippingOption } from '@consumer/logic/shipping'

export interface GiftFeeScheduleItem {
  min: number
  max: number | null
  fee: number
}

export interface ShippingDates {
  firstclass: DateStr
  priority: DateStr
}

export interface CheckoutConfig {
  loaded?: boolean
  savedCard?: CreditCard
  christmasDeliveryDateCutoffs?: ShippingDates
  shipping: {
    baseFee: number
    options: ShippingOption[]
  }
  giftFeeSchedule: GiftFeeScheduleItem[]
  greetingCards: {
    categories: GreetingCardCategory[]
  }
  configureShippingEstimates: (estimates: ShippingDates) => void
  load: (dynamicGreetingCardCategories?: boolean) => void
}

interface CheckoutConfigurationResponse {
  christmasDeliveryDateCutoffs?: ShippingDates
  shippingEstimates: ShippingDates
  giftFeeSchedule: GiftFeeScheduleItem[]
  savedCard: CreditCard
}

const checkoutConfig: CheckoutConfig = reactive({
  shipping: {
    baseFee: 5,
    options: shippingOptions,
  },
  giftFeeSchedule: [],
  greetingCards: {
    categories: [],
  },
  configureShippingEstimates: ({ firstclass: standard, priority }) => {
    const firstclassShippingEstimate = parseDate(standard, 'yyyy-MM-dd')
    const priorityShippingEstimate = parseDate(priority, 'yyyy-MM-dd')

    checkoutConfig.shipping.options[0].estimatedDeliveryDate = firstclassShippingEstimate
    checkoutConfig.shipping.options[1].estimatedDeliveryDate = firstclassShippingEstimate
    checkoutConfig.shipping.options[2].estimatedDeliveryDate = priorityShippingEstimate
  },
  load: () => {
    const greetingCardsApi = greetingCards.categories

    Promise.all([
      checkoutApi.configuration<CheckoutConfigurationResponse>(),
      greetingCardsApi<GreetingCardCategory[]>(),
    ]).then((values) => {
      const [configData, greetingCardCategories] = values
      checkoutConfig.savedCard = configData.savedCard
      checkoutConfig.giftFeeSchedule = configData.giftFeeSchedule
      checkoutConfig.configureShippingEstimates(configData.shippingEstimates)

      if (configData.christmasDeliveryDateCutoffs) {
        checkoutConfig.christmasDeliveryDateCutoffs = {
          firstclass: parseDate(configData.christmasDeliveryDateCutoffs?.firstclass, 'yyyy-MM-dd'),
          priority: parseDate(configData.christmasDeliveryDateCutoffs?.priority, 'yyyy-MM-dd'),
        }
      }
      checkoutConfig.greetingCards.categories = greetingCardCategories

      checkoutConfig.loaded = true
    })
  },
})

export default checkoutConfig

<script setup lang="ts">import { ref as _ref, toRef as _toRef } from 'vue';
const __props_currentUser = _toRef(__props, 'currentUser');

import { type BuyerGift, type CurrentUser, type FeaturedCity } from '@consumer/types/serializers'

import { useUrlSearchParams } from '@vueuse/core'
import { buyerLanding } from '@consumer/api'
import { asyncOpenModal } from '@consumer/services/modals'
import HeaderCheckout from './HeaderCheckout.vue'
import HeaderDefault from './HeaderDefault.vue'
import HeaderModal from './HeaderModal.vue'
import HeaderProduct from './HeaderProduct.vue'
import HeaderSimple from './HeaderSimple.vue'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  type?: 'default' | 'product' | 'simple' | 'checkout' | 'modal'
  currentUser?: CurrentUser | null
  currentTheme?: string | null
  city?: FeaturedCity | null
}>(), {  })

provide('currentUser', (__props_currentUser))
provide('currentTheme', __props.currentTheme)

const headerComponent = computed(() => {
  switch (__props.type) {
    case 'checkout':
      return HeaderCheckout
    case 'modal':
      return HeaderModal
    case 'product':
      return HeaderProduct
    case 'simple':
      return HeaderSimple
    default:
      return HeaderDefault
  }
})

const headerTypeRef = _ref<InstanceType<
  typeof HeaderDefault |
  typeof HeaderModal |
  typeof HeaderCheckout |
  typeof HeaderSimple |
  typeof HeaderProduct
>>()

defineExpose({
  closeButtonEl: (headerTypeRef.value as any)?.closeButtonEl,
})

const emit = defineEmits(['close'])

const searchParams = useUrlSearchParams()

onMounted(() => {
  if (searchParams.spec === 'complete_account_setup' && searchParams.gift_id) {
    const giftId = searchParams.gift_id.toString()

    buyerLanding.fetchGift<BuyerGift>({ id: giftId }).then((gift) => {
      asyncOpenModal(import('@consumer/modals/AccountCreatedModal.vue'), { gift })
    }).catch(() => {
      // no-op: don't show the modal if the gift can't be fetched
    })

    delete searchParams.spec
    delete searchParams.gift_id
  }
})
</script>

<template>
  <header id="v2-header" class="w-full bg-white flex flex-col !border-none" v-bind="$attrs">
    <component :is="headerComponent" ref="headerTypeRef" :city="city" @close="emit('close')">
      <slot/>
    </component>
  </header>
</template>

<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useFieldWithErrors } from '@consumer/composables/fieldWithErrors'
import { Dropdown } from 'floating-vue'

withDefaults(defineProps<{
  name?: string
  id?: string
  label?: string
  icon?: any | null
  iconRight?: any | null
  placeholder?: string
  compact?: boolean
  type?: string
  enterkeyhint?: string
  hint?: string
  error?: string | string[]
  errorTooltip?: boolean
  forceBold?: boolean
}>(), { type: 'text',forceBold: false, })

const model = defineModel<string>()

const inputId = `form-input-${__props.id || useId()}`

interface Emit {
  (event: 'focus', value: Event): void
  (event: 'blur', value: Event): void
  (event: 'input', value: Event): void
}
const emit = defineEmits<Emit>()

const slots = useSlots()

const hasLabel = computed(() => Boolean(slots.label) || __props.label)

const hasAddonLeft = computed(() => Boolean(slots.addonLeft || __props.icon))

const hasAddonRight = computed(() => Boolean(slots.addonRight || __props.iconRight))

const inputEl = ref<HTMLInputElement>()

const __$temp_1 = (useFieldWithErrors(
  inputEl,
  () => __props.error,
  () => __props.errorTooltip,
)),
  showError = _toRef(__$temp_1, 'showError'),
  errorMessages = _toRef(__$temp_1, 'errorMessages'),
  errorTooltipPlacement = _toRef(__$temp_1, 'errorTooltipPlacement');

defineExpose({
  focus: () => inputEl.value?.focus(),
  blur: () => inputEl.value?.blur(),
})
</script>

<template>
  <fieldset class="text-v2-primary v2-input-field group relative w-full">
    <div v-if="hasLabel || hint" class="flex items-center justify-between mb-1">
      <slot v-if="hasLabel" name="label">
        <V2Label :for="inputId" class="text-sm !font-semibold">
          <span v-html="label"/>
        </V2Label>
      </slot>
      <span class="font-normal text-sm text-grey-500">
        <slot name="hint">
          <span v-html="hint"/>
        </slot>
      </span>
    </div>
    <div class="v2-input-field-group relative">
      <div
        v-if="hasAddonLeft"
        :class="[
          'absolute inset-y-0 left-0 pl-4 flex items-center'
        ]"
      >
        <slot name="addonLeft">
          <V2Icon
            v-if="icon"
            :icon="icon"
            size="sm"
            class="v2-input-field-icon v2-input-field-icon-left text-v2-tertiary"
          />
        </slot>
      </div>

      <input
        :id="inputId"
        ref="inputEl"
        v-model="model"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :class="[
          'text-base text-navy placeholder-v2-tertiary',
          'outline-none rounded-v2md shadow-v2sm',
          'border-1 border-solid border-grey-primary',
          'h-full w-full flex items-center p-4',
          'focus:bg-surface-blue',
          hasAddonLeft ? 'pl-11' : '',
          hasAddonRight ? 'pr-11' : '',
          model || forceBold ? 'font-semibold' : 'font-medium',
          'v2-input-field-input'
        ]"
        v-bind="$attrs"
        autocomplete="off"
        data-1p-ignore
        :enterkeyhint="enterkeyhint"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
        @input="emit('input', $event)"
      >

      <Dropdown
        v-if="errorTooltip && showError"
        shown
        :triggers="[]"
        :autoHide="false"
        :flip="false"
        :placement="errorTooltipPlacement"
        popperClass="g-input-field-error-tooltip"
        :arrowPadding="8"
        :distance="8"
      >
        <template #popper>
          <ul class="p-4 text-red-base text-[14px]">
            <li v-for="errorMessage, i in errorMessages" :key="i">
              {{ errorMessage }}
            </li>
          </ul>
        </template>
      </Dropdown>

      <div
        v-if="hasAddonRight"
        :class="[
          'absolute inset-y-0 right-0 pr-4 flex items-center'
        ]"
      >
        <slot name="addonRight">
          <V2Icon
            v-if="iconRight"
            :icon="iconRight"
            size="sm"
            class="v2-input-field-icon v2-input-field-icon-right text-v2-tertiary"
          />
        </slot>
      </div>
    </div>
  </fieldset>
</template>

<style lang="css">
.v2-input-field-input::placeholder {
  transform: translateY(2px);

  /* line-height:600px; */
}
</style>

<script lang="ts" setup>import { computed as _computed } from 'vue';

import GLink from '@consumer/components/GLink.vue'

withDefaults(defineProps<{
  variant?: 'primary' | 'secondary' | 'tertiary'
  label?: string
  href?: string
  disabled?: boolean
  processing?: boolean
  as?: any
  name?: string
  rel?: string
  size?: 'default' | 'small'
  icon?: any
  iconRight?: any
}>(), { size: 'default', })

const componentAs = _computed(() => __props.as ?? (__props.href ? GLink : 'button'))

const slots = useSlots() as Record<string, any>

const iconOnly = computed(() => __props.icon && !slots.default && !__props.label && !__props.iconRight)
</script>

<template>
  <component
    :is="componentAs"
    :type="href ? undefined : 'button'"
    :name="name"
    :href="href"
    class="v2-button flex items-center justify-center gap-2
      px-8 font-semibold"
    :class="[
      `v2-button-${variant}`,
      `v2-button-size-${size}`,
      {
        'v2-button-icon-only': iconOnly,
        disabled,
        '!cursor-wait': processing,
      },
    ]"
    :disabled="disabled || undefined"
    :rel="rel"
  >
    <LoadingIndicator v-if="processing" :size="size === 'small' ? 'sm' : 'md'"/>
    <slot v-else>
      <V2Icon v-if="icon" :icon="icon" :size="size === 'small' ? 'sm' : 'md'"/>
      {{ label }}
      <V2Icon v-if="iconRight" :icon="iconRight" :size="size === 'small' ? 'sm' : 'md'"/>
    </slot>
  </component>
</template>

<style lang="scss">
.v2-button {
  @apply rounded-v2md shadow-v2sm;

  font-size: 18px;
  padding: 16px 32px;

  &-primary {
    @apply bg-v2-active hover:bg-v2-blue-cerulean text-white;
  }

  &-secondary {
    @apply bg-white text-v2-active hover:text-v2-blue-cerulean hover:bg-grey-primary;
    @apply border-grey-primary border-1 border-solid;
  }

  &-tertiary {
    @apply bg-white border-grey-primary border-1 border-solid;
    @apply text-v2-secondary hover:text-v2-active hover:border-v2-active;
  }

  &:disabled {
    @apply bg-grey-primary text-v2-tertiary;
  }

  &-icon-only {
    padding: 16px;
  }

  &-size-small {
    font-size: 16px;
    padding: 12px 32px;

    &.v2-button-icon-only {
      padding: 12px;
    }
  }
}
</style>

<script setup lang="ts">
import UserMenuMobileModal from '@consumer/modules/v2/layout/Header/UserMenu/MobileModal.vue'
import type { CurrentUser } from '@consumer/types/serializers'
import BuyAGift from './UserMenu/BuyAGift.vue'
import Menu from './UserMenu/Menu.vue'
import MenuButton from './UserMenu/MenuButton.vue'

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

const open = ref(false)
</script>

<template>
  <div>
    <div
      class="relative inline-block text-left"
      @click="open = !open"
    >
      <MenuButton v-model:open="open" :currentUser="currentUser"/>
    </div>

    <UserMenuMobileModal v-model:open="open">
      <div class="p-4 pt-v2-10 flex flex-col">
        <Menu v-model:open="open"/>
        <div class="flex-1">
          <BuyAGift style=" height: 100%; min-height: 320px;width: 100%;"/>
        </div>
      </div>
    </UserMenuMobileModal>
  </div>
</template>
